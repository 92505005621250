'use client';

import React, { ReactElement, Suspense, useEffect, useState } from 'react';
import { FiX as CrossIcon } from 'react-icons/fi';
import { useTranslations } from 'next-intl';
import { Form, Formik } from 'formik';
import { useAppContext } from '@/lib/context';
import { useSearchBar } from './hooks/useSearchBar';
import clsx from 'clsx';
import Autocomplete from './modules/Autocomplete';
import SearchField from './modules/SearchField';
import Button from '@/components/Button';

import classes from './searchBar.module.scss';

const SearchBar = (): ReactElement => {
    const [
        { isHeaderNewDesign: isNewDesign, isSearchOpen },
        {
            actions: { setIsSearchOpen },
        },
    ] = useAppContext();
    const t = useTranslations('global');

    const [shouldDisplayResult, setShouldDisplayResult] = useState(false);
    const [wasSearchInteractedWith, setWasSearchInteractedWith] = useState(false);

    const { handleSubmit, initialValues } = useSearchBar({ isSearchOpen, setIsSearchOpen });

    // We are blocking any search animation that may otherwise run on every page load.
    // It's influencing the root class of this component.
    useEffect(() => {
        isSearchOpen ? setWasSearchInteractedWith(true) : null;
    }, [isSearchOpen, setWasSearchInteractedWith]);

    const closeSearchButton = isSearchOpen ? (
        <Button
            variant="trigger"
            onClick={() => {
                setIsSearchOpen(false);
            }}
            className={isNewDesign ? classes.searchCloseTriggerNewDesign : classes.searchCloseTrigger}
            type="button"
            title={t('close')}
        >
            <CrossIcon size={28} color="white" />
        </Button>
    ) : null;

    const rootClassName = isSearchOpen
        ? classes.rootActive
        : wasSearchInteractedWith
          ? `${classes.root} ${classes.wasSearchInteractedWith}`
          : classes.root;

    return (
        <>
            <div className={rootClassName} data-mt-type="search-bar">
                <div className={classes.container}>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        <Form
                            autoComplete="off"
                            className={clsx(classes.searchForm, isNewDesign && classes.searchFormNewDesign)}
                        >
                            <Suspense>
                                <SearchField
                                    field="search_query"
                                    isSearchOpen={isSearchOpen}
                                    setIsSearchOpen={setIsSearchOpen}
                                    shouldDisplayResult={shouldDisplayResult}
                                />
                                <Autocomplete
                                    setIsSearchOpen={setIsSearchOpen}
                                    isSearchOpen={isSearchOpen}
                                    setShouldDisplayResult={setShouldDisplayResult}
                                />
                            </Suspense>
                        </Form>
                    </Formik>
                </div>
            </div>
            {closeSearchButton}
        </>
    );
};

export default SearchBar;
