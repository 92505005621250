import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslations } from 'next-intl';
import { useSearchBar } from '@/components/SearchBar/hooks/useSearchBar';
import { useSearchParams } from 'next/navigation';
import useKeyPress from '@/hooks/useKeyPressed';
import useIsDesktop from '@/hooks/useIsDesktop';
import useIsMobile from '@/hooks/useIsMobile';

import type { IUseSearchField, IUseSearchFieldProps } from '@/components/SearchBar/types';

export const SEARCH_QUERY_FIELD = 'search_query';

export const useSearchField = ({
    isSearchOpen,
    setIsSearchOpen,
    shouldDisplayResult,
}: IUseSearchFieldProps): IUseSearchField => {
    const tSearchField = useTranslations('searchField');
    const [cursor, setCursor] = useState(-1);
    const [suggestionValue, setSuggestionValue] = useState<string | null>(null);
    const [suggestionsNodeList, setSuggestionsNodeList] = useState<NodeListOf<Element> | undefined>(undefined);
    const [searchInputLabel, setSearchInputLabel] = useState<string>(tSearchField('searchPlaceholder'));

    const inputRef = useRef<HTMLInputElement>();
    const isDesktop = useIsDesktop();
    const isMobile = useIsMobile();
    const searchParams = useSearchParams();
    const { setFieldValue, values } = useFormikContext<{ [SEARCH_QUERY_FIELD: string]: string }>();
    const searchQueryValue = values[SEARCH_QUERY_FIELD];
    const { handleSubmit } = useSearchBar({ isSearchOpen, setIsSearchOpen });

    const downPress = useKeyPress('ArrowDown', inputRef, true);
    const upPress = useKeyPress('ArrowUp', inputRef, true);
    const escapePress = useKeyPress('Escape', inputRef, false);

    const inputValueLength = suggestionValue?.length ? suggestionValue.length : searchQueryValue?.length;

    const resetForm = useCallback(() => {
        setFieldValue(SEARCH_QUERY_FIELD, '');
        // We have to set this value to null manually. @see https://javascript.info/dom-attributes-and-properties#property-attribute-synchronization
        // It's being read in focus handler below.

        if (inputRef?.current) {
            inputRef.current.value = '';
            inputRef.current.focus();
        }
    }, [setFieldValue]);

    // Expand or collapse on input change
    const handleChange = useCallback(
        (value: string) => {
            //reset keyboard navigation cursor on change
            setCursor(-1);
            isDesktop ? setIsSearchOpen(!!value) : setIsSearchOpen(true);
        },
        [isDesktop, setIsSearchOpen],
    );

    useEffect(() => {
        isSearchOpen && !isMobile ? inputRef?.current?.focus() : null;
    }, [isMobile, isSearchOpen]);

    // Pre-populate the search field with the search term from the URL.
    // We purposefully only ever run this effect on initial mount.
    useEffect(() => {
        const urlTerm = searchParams.get('query');

        if (!setFieldValue || !urlTerm) {
            return;
        }

        setFieldValue(SEARCH_QUERY_FIELD, urlTerm);
    }, [searchParams, setFieldValue]);

    // Every time new data is fetched and can be displayed (isn't loading anymore, @see useAutocomplete),
    // we set a fresh suggestions list, and manipulate it.
    // We also have to reset cursor's position.
    useEffect(() => {
        if (!shouldDisplayResult) {
            return;
        }

        const searchSuggestionsItemsNodeList = document.querySelectorAll('[data-suggestion-item]');

        // Here we are adding an id to every suggestion element, but only when suggestions list has changed.
        searchSuggestionsItemsNodeList?.forEach((suggestion, id) => {
            suggestion.setAttribute('id', `data-suggestion-id-${id}`);
        });

        setSuggestionsNodeList(searchSuggestionsItemsNodeList);
        setCursor(-1);
    }, [shouldDisplayResult]);

    // Every time the cursor's value changes, we search for a new DOM element, and highlight it. We also un-highlight all other suggestions, and preserve the selected value in state.
    const selectedSuggestionElement = useMemo(() => {
        if (!globalThis.document) {
            return;
        }

        const element = document.getElementById(`data-suggestion-id-${cursor}`);

        if (suggestionsNodeList) {
            suggestionsNodeList.forEach((suggestion) => {
                suggestion.classList.remove('suggestionSelected');
            });
            element?.classList.add('suggestionSelected');

            // Changes masked value to preserve it in input on submit
            setSuggestionValue(suggestionsNodeList[cursor]?.getAttribute('data-suggestion-value'));
        }

        return element;
    }, [cursor, suggestionsNodeList]);

    // Select and deselect input text, to signalize when the input field is focused and no suggestions are selected
    // only run this on cursor index change
    useEffect(() => {
        if (!isSearchOpen) return;

        if (cursor === -1 && (downPress || upPress)) {
            inputRef?.current?.setSelectionRange(0, inputValueLength);

            return;
        }

        inputRef?.current?.setSelectionRange(inputValueLength, inputValueLength);
    }, [cursor]);

    // Suggestions data that we already fetched is preserved. It means that we would still advance our cursor even if autocomplete is closed.
    // We block this behaviour here, and only advance cursor's value when autocomplete is open. If it's open, cursor may change freely.
    // Cursor is blocked when new data is fetching.
    // The same applies to upPress event below.
    useEffect(() => {
        if (!shouldDisplayResult) {
            return;
        }

        if (!suggestionsNodeList?.length || !downPress) {
            return;
        }

        if (isSearchOpen) {
            setCursor((prevState) =>
                prevState < suggestionsNodeList?.length - 1
                    ? prevState + 1
                    : suggestionsNodeList?.length - 1
                      ? -1
                      : prevState,
            );
        }

        setIsSearchOpen(true);
    }, [downPress, isSearchOpen, setIsSearchOpen, shouldDisplayResult, suggestionsNodeList?.length]);

    useEffect(() => {
        if (!shouldDisplayResult) {
            return;
        }

        if (!suggestionsNodeList?.length || !upPress) {
            return;
        }

        if (isSearchOpen) {
            setCursor((prevState) =>
                prevState > -1 ? prevState - 1 : prevState === -1 ? suggestionsNodeList?.length - 1 : prevState,
            );
        }

        setIsSearchOpen(true);
    }, [isSearchOpen, setIsSearchOpen, shouldDisplayResult, suggestionsNodeList?.length, upPress]);

    // We can close search by hitting escape. All data is preserved, but we expect to lose input's focus.
    useEffect(() => {
        if (!escapePress) {
            return;
        }

        isSearchOpen && setIsSearchOpen(false);
        inputRef?.current?.blur();
    }, [escapePress, isSearchOpen, setIsSearchOpen]);

    const handleEnter = useCallback(
        (e: any) => {
            if (e.key !== 'Enter' && e.key !== '13') return;

            e.preventDefault();

            isSearchOpen && setIsSearchOpen(false);

            if (cursor === -1) {
                handleSubmit({ search_query: searchQueryValue });

                return;
            }

            if (suggestionsNodeList?.length) {
                selectedSuggestionElement
                    ? // Navigating to categories or product suggestion results in initially typed input value.
                      // It's good, because we remain focused on input, and can hit up or down arrow to resume searching.
                      // No more typing needed. We do not repeat ourselves. Convenient.
                      selectedSuggestionElement?.click()
                    : // Changes actual input's value
                      handleSubmit({ search_query: searchQueryValue });
            }
        },
        [cursor, isSearchOpen, suggestionsNodeList?.length, searchQueryValue],
    );

    useEffect(() => {
        if (isMobile) {
            setSearchInputLabel(tSearchField('searchPlaceholderMobile'));
        }
    }, [isMobile, tSearchField]);

    const submitAndCloseSearch = useCallback(() => {
        handleSubmit({ search_query: searchQueryValue });
        setIsSearchOpen(false);
    }, [handleSubmit, searchQueryValue, setIsSearchOpen]);

    return {
        handleChange,
        handleEnter,
        inputRef,
        isMobile,
        resetForm,
        searchInputLabel,
        submitAndCloseSearch,
        suggestionValue,
        value: searchQueryValue,
    };
};
