import { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { GET_ADDITIONAL_INFORMATION } from '@/components/SearchBar/api/getAdditionalInformation.gql';

import { useFormikContext } from 'formik';
import debounce from 'lodash.debounce';

import { useScrollLock } from '@/hooks/useScrollLock';
import { SEARCH_RESULT_STATUS } from '@/talons/CurrentStatus/useCurrentSearchStatus';
import type { IFredhopperSuggestionsData, IUseAutocomplete, IUseAutocompleteProps } from '@/components/SearchBar/types';

const INDEX_NAME_CATEGORIES = 'categories';
const INDEX_NAME_KEYWORDS = 'keywords';
const INDEX_NAME_PRODUCTS = 'products';

export const useAutocomplete = ({ isSearchOpen }: IUseAutocompleteProps): IUseAutocomplete => {
    const {
        data: dependencyData,
        error: couldNotGetDependencyData,
        loading: isFetchingDependencyData,
    } = useQuery(GET_ADDITIONAL_INFORMATION, {
        fetchPolicy: 'cache-and-network',
        skip: !isSearchOpen,
    });

    const { values } = useFormikContext<{ search_query: string }>();
    const searchQueryValue = values.search_query;

    const dependencies = useMemo(() => {
        if (isFetchingDependencyData || !dependencyData) {
            return;
        }

        return {
            fredHopperUri: dependencyData.searchSuggestBasePath.result,
            productCurrency: dependencyData.currency?.default_display_currency_code,
        };
    }, [isFetchingDependencyData, dependencyData]);

    const [searchData, setSearchData] = useState(new Map());
    const [status, setStatus] = useState<string>(isFetchingDependencyData ? 'loading' : 'error');
    const [hasResult, setHasResult] = useState<boolean>(false);
    const lastAbortController = useRef<AbortController | null>(null);

    const errorOut = useCallback(() => {
        setStatus('error');
        searchData && setSearchData(new Map());
    }, [searchData]);

    useScrollLock(isSearchOpen);

    const debouncedRunFetch = useMemo(
        () =>
            debounce((search_query) => {
                if (lastAbortController.current) lastAbortController.current?.abort();

                lastAbortController.current = new AbortController();

                const trimmedSearchQuery = search_query?.trim();

                if (!trimmedSearchQuery) {
                    lastAbortController.current.abort();
                    setStatus('aborted');
                }

                fetch(dependencies?.fredHopperUri?.replace('{searchText}', trimmedSearchQuery), {
                    cache: 'default',
                    headers: {
                        accept: 'application/json',
                    },
                    method: 'GET',
                    mode: 'no-cors',
                    signal: lastAbortController.current?.signal,
                })
                    .then((response) => response.json())
                    .then((result: IFredhopperSuggestionsData) => {
                        if (lastAbortController.current?.signal.aborted) {
                            return;
                        }

                        const responseMap = new Map();
                        const data = result.suggestionGroups;

                        data.map(({ indexName, suggestions }) => {
                            if (indexName.includes(INDEX_NAME_CATEGORIES) && suggestions.length) {
                                responseMap.set(INDEX_NAME_CATEGORIES, suggestions);
                            }

                            if (indexName.includes(INDEX_NAME_PRODUCTS) && suggestions.length) {
                                const updatedSuggestions = suggestions.map((product) => ({
                                    ...product,
                                    currency: dependencies?.productCurrency,
                                }));

                                responseMap.set(INDEX_NAME_PRODUCTS, updatedSuggestions);
                            }

                            if (indexName.includes(INDEX_NAME_KEYWORDS) && suggestions.length) {
                                responseMap.set(INDEX_NAME_KEYWORDS, suggestions);
                            }
                        });

                        setSearchData(responseMap);

                        lastAbortController.current = null;

                        setStatus('loaded');
                    })
                    .catch((error) => {
                        if (error.name !== 'AbortError') setStatus('error');
                    });
            }, 1000),
        [dependencies],
    );

    useEffect(() => {
        if ((dependencies && !Object.values(dependencies).every(Boolean)) || couldNotGetDependencyData)
            return errorOut();

        if (isSearchOpen) {
            setStatus('loading');
            // Fredhopper's default suggestions are being called with a minus sign in place of a search query
            debouncedRunFetch(searchQueryValue || '-');
        }

        !searchQueryValue && setSearchData(new Map());
    }, [dependencies, debouncedRunFetch, isSearchOpen, searchQueryValue, couldNotGetDependencyData]);

    useEffect(() => {
        setHasResult(!!searchData?.size);
    }, [searchData]);

    const isLoading = status === 'loading';
    const shouldDisplayResult = !!(hasResult && !isLoading);

    const returnMessageType = () => {
        if (status === 'error') return SEARCH_RESULT_STATUS.error;
        if (searchData && !isLoading && !hasResult) return SEARCH_RESULT_STATUS.empty;

        return '';
    };

    const messageType = returnMessageType();

    return {
        isSuggestionsFetchRunning: isLoading,
        messageType,
        searchQueryValue,
        shouldDisplayResult,
        suggestedCategories: searchData.get(INDEX_NAME_CATEGORIES) || [],
        suggestedKeywords: searchData.get(INDEX_NAME_KEYWORDS) || [],
        suggestedProducts: searchData.get(INDEX_NAME_PRODUCTS) || [],
    };
};
