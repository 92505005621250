import { useCallback, useEffect } from 'react';
import type { IEqualWebConfig } from '@/components/EqualWeb/types';

export const ACCESSIBILITY_ENABLED = 'accessibility-enabled';
interface IUseEqualWeb {
    onClick: () => void;
}

export const useEqualWeb = ({
    equalWebIntegrityKey,
    equalWebScriptVersion,
    equalWebWebsiteKey,
    isEqualWebEnabled,
    storeName,
}: IEqualWebConfig): IUseEqualWeb => {
    const initializeEqualWeb = useCallback(() => {
        if (globalThis?.window?.interdeal) return;

        globalThis.window.interdeal = {
            Menulang: storeName,
            Position: 'Left',
            domains: {
                acc: 'https://access.equalweb.com/',
                js: 'https://cdn.equalweb.com/',
            },
            hideBtn: true,
            sitekey: equalWebWebsiteKey,
        };

        const script = Object.assign(document.createElement('script'), {
            crossOrigin: 'anonymous',
            defer: true,
            integrity: equalWebIntegrityKey,
            src: `https://cdn.equalweb.com/core/${equalWebScriptVersion}/accessibility.js`,
        });

        globalThis.document.body.appendChild(script);

        return () => {
            globalThis.document.body.removeChild(script);
        };
    }, [equalWebWebsiteKey, equalWebIntegrityKey]);

    useEffect(() => {
        if (!equalWebIntegrityKey || !equalWebScriptVersion || !equalWebWebsiteKey || !isEqualWebEnabled) {
            return;
        }

        initializeEqualWeb();
    }, [equalWebIntegrityKey, equalWebScriptVersion, equalWebWebsiteKey, initializeEqualWeb, isEqualWebEnabled]);

    const onClick = useCallback(() => {
        globalThis.localStorage.setItem(ACCESSIBILITY_ENABLED, 'true');

        if (globalThis?.window?.interdeal) {
            globalThis.window.interdeal.a11y.openMenu();
        }
    }, []);

    return {
        onClick,
    };
};
