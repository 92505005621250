'use client';

import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';
import { useEqualWeb } from '@/talons/EqualWeb/useEqualWeb';

import type { IEqualWebConfig } from './types';
import classes from './equalWeb.module.scss';
import './equalWeb.scss';

declare global {
    interface Window {
        interdeal?: any;
    }
}

const EqualWeb = ({
    equalWebIntegrityKey,
    equalWebScriptVersion,
    equalWebWebsiteKey,
    isEqualWebEnabled,
    storeName,
}: IEqualWebConfig): ReactElement | null => {
    const { onClick } = useEqualWeb({
        equalWebIntegrityKey,
        equalWebScriptVersion,
        equalWebWebsiteKey,
        isEqualWebEnabled,
        storeName,
    });

    const t = useTranslations('accessibility');

    if (!isEqualWebEnabled) {
        return null;
    }

    return (
        <button className={classes.button} onClick={onClick}>
            {t('enableAccessibility')}
        </button>
    );
};

EqualWeb.displayName = 'EqualWeb';

export default EqualWeb;
